// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bK_k4";
export var caseStudyBackground__lineColor = "bK_kW";
export var caseStudyHead__imageWrapper = "bK_kT";
export var caseStudyProjectsSection = "bK_k5";
export var caseStudyQuote__bgLight = "bK_k2";
export var caseStudyQuote__bgRing = "bK_k1";
export var caseStudySolution__ring = "bK_kZ";
export var caseStudyVideo__ring = "bK_k8";
export var caseStudy__bgDark = "bK_kS";
export var caseStudy__bgLight = "bK_kR";